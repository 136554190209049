import "./App.css";
import Logo from "./images/linktree3rb.png";

function App() {
  const ButtonData = [
    {
      title: "Show 1",
      linkUrl: "https://show1.q-tech.dev",
    },
    {
      title: "Show 2",
      linkUrl: "https://show2.q-tech.dev",
    },
    {
      title: "Show 3",
      linkUrl: "https://show3.q-tech.dev",
    },
    {
      title: "Show 4",
      linkUrl: "https://show4.q-tech.dev",
    },
    {
      title: "Show 5",
      linkUrl: "https://show5.q-tech.dev",
    },
    {
      title: "Show 6",
      linkUrl: "https://show6.q-tech.dev",
    },
  ];

  const Button1Data = [
    {
      title: "Q Artikel AI",
      linkUrl: "https://q-sum.netlify.app/",
    },

    {
      title: "Moviezland",
      linkUrl: "https://moviezland.de",
    },

    {
      title: "Lerne Krypto",
      linkUrl: "https://learn.q-tech.dev/",
    },

    {
      title: "Q-Future",
      linkUrl: "https://q-future.org/",
    },

    {
      title: "Q-Energy Experts",
      linkUrl: "https://Q-Energyexperts.de/",
    },

  ];

  const SupportData = [
    {
      title: "Isyanworld",
      linkUrl: "https://isyanworld.de",
    },
  ];

  return (
    <div className="App">
      <div className="bild">
        <img src={Logo} alt="Q-Tech.dev Logo" />
        <h1>Qualified Technologies Development</h1>
        <div className="hero__btns">
            <a href="https://q-tech.dev" rel="noreferrer" target="_blank">
              <button className="primary__btn">Q-Tech Dev</button>
            </a>
          </div>
      </div>

      <div className="buttons">
        <h2>Show Webseiten</h2>
        {ButtonData.map((item, index) => (
          <div className="hero__btns" key={index}>
            <a href={item.linkUrl} rel="noreferrer" target="_blank">
              <button className="primary__btn">{item.title}</button>
            </a>
          </div>
        ))}
      </div>

      <div className="buttons">
        <h2>Nützliche Webseiten</h2>
        {Button1Data.map((item, index) => (
          <div className="hero__btns" key={index}>
            <a href={item.linkUrl} rel="noreferrer" target="_blank">
              <button className="primary__btn">{item.title}</button>
            </a>
          </div>
        ))}
      </div>

      <div className="buttons1">
        <h2>Support</h2>
        {SupportData.map((item, index) => (
          <div className="hero__btns" key={index}>
            <a href={item.linkUrl} rel="noreferrer" target="_blank">
              <button className="primary__btn">{item.title}</button>
            </a>
          </div>
        ))}
      </div>
    </div>
  );
}

export default App;
